import React, { useState } from 'react';
import { Share2, Bookmark, BookmarkCheck, ArrowRight } from 'lucide-react';
import trending1 from '../images/venues/blogs/Trending-Venue-Styles-Resort.jpg'
import trending2 from '../images/venues/blogs/Trending-Venue-Styles-Outdoor.jpg'
import trending4 from '../images/venues/blogs/Trending-Venue-Weddings.jpg'

const TrendingContents = () => {
  const [savedArticle, setSavedArticle] = useState(false);
  const [activeTab, setActiveTab] = useState('all');

  const trends = [
    {
      id: 1,
      title: "Hybrid-Ready Spaces",
      category: "technology",
      description: "Modern venues embracing the fusion of physical and digital experiences.",
      image: trending4,
      features: [
        "Built-in high-speed internet infrastructure",
        "Professional lighting for both in-person and virtual attendees",
        "Multiple camera angles and streaming capabilities",
        "Interactive digital walls",
        "Dedicated virtual guest hosting areas"
      ]
    },
    {
      id: 2,
      title: "Sustainable Eco-Venues",
      category: "sustainability",
      description: "Environmental consciousness is no longer optional in modern venues.",
      image: trending1,
      features: [
        "Solar-powered facilities",
        "Living walls and vertical gardens",
        "Rainwater harvesting systems",
        "Zero-waste policies",
        "Natural lighting optimization"
      ]
    },
    {
      id: 3,
      title: "Industrial Chic 2.0",
      category: "design",
      description: "The industrial style evolving with softer elements.",
      image: trending2,
      features: [
        "Exposed brick walls with living plants",
        "High ceilings with modern chandeliers",
        "Polished concrete floors",
        "Smart-tinting windows",
        "Metal and wood combinations"
      ]
    }
  ];

  const categories = [
    { id: 'all', name: 'All Trends' },
    { id: 'technology', name: 'Technology' },
    { id: 'sustainability', name: 'Sustainability' },
    { id: 'design', name: 'Design' }
  ];

  const filteredTrends = activeTab === 'all' 
    ? trends 
    : trends.filter(trend => trend.category === activeTab);

  return (
    <div className="py-16 px-4 max-w-7xl mx-auto">
      {/* Header Section */}
      <div className="relative mb-8">
        <img 
          src={trending4} 
          alt="Modern Venue Trends" 
          className="w-full h-64 object-cover rounded-lg shadow-lg"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent rounded-lg">
          <div className="absolute bottom-0 p-6">
            <h1 className="text-4xl font-bold text-white mb-2">
              Trending Venue Styles for 2025
            </h1>
            <p className="text-gray-200">
              Discover the future of event spaces and venue design
            </p>
          </div>
        </div>
      </div>

      {/* Action Bar */}
      <div className="flex justify-between items-center mb-8">
        <div className="flex space-x-2">
          <button 
            onClick={() => {}} 
            className="flex items-center px-4 py-2 bg-gray-100 rounded-full hover:bg-gray-200"
          >
            <Share2 className="w-4 h-4 mr-2" />
            Share
          </button>
          <button 
            onClick={() => setSavedArticle(!savedArticle)} 
            className="flex items-center px-4 py-2 bg-gray-100 rounded-full hover:bg-gray-200"
          >
            {savedArticle ? (
              <BookmarkCheck className="w-4 h-4 mr-2 text-green-500" />
            ) : (
              <Bookmark className="w-4 h-4 mr-2" />
            )}
            {savedArticle ? 'Saved' : 'Save'}
          </button>
        </div>
        <span className="text-gray-500">Reading time: 8 min</span>
      </div>

      {/* Category Tabs */}
      <div className="flex space-x-4 mb-8 overflow-x-auto pb-2">
        {categories.map(category => (
          <button
            key={category.id}
            onClick={() => setActiveTab(category.id)}
            className={`px-4 py-2 rounded-full whitespace-nowrap ${
              activeTab === category.id
                ? 'bg-blue-500 text-white'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            {category.name}
          </button>
        ))}
      </div>

      {/* Trends Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
        {filteredTrends.map(trend => (
          <div key={trend.id} className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow">
            <img 
              src={trend.image}
              alt={trend.title} 
              className="w-full h-48 object-cover rounded-lg mb-4"
            />
            <h3 className="text-xl font-bold mb-2">{trend.title}</h3>
            <p className="text-gray-600 mb-4">{trend.description}</p>
            <ul className="space-y-2">
              {trend.features.map((feature, index) => (
                <li key={index} className="flex items-center text-gray-700">
                  <ArrowRight className="w-4 h-4 mr-2 text-blue-500" />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* Call to Action */}
      <div className="bg-blue-50 rounded-lg p-8 text-center">
        <h2 className="text-2xl font-bold mb-4">Ready to explore these trending venues?</h2>
        <p className="text-gray-600 mb-6">
          Browse our curated selection of modern event spaces and find the perfect venue for your next event.
        </p>
        <button className="bg-blue-500 text-white px-8 py-3 rounded-lg hover:bg-blue-600 transition-colors">
          Browse Trending Venues
        </button>
      </div>

      {/* Tags */}
      <div className="mt-8 flex flex-wrap gap-2">
        {['#EventVenues', '#VenueTrends', '#EventPlanning', '#2025Trends', '#ModernVenues'].map(tag => (
          <span key={tag} className="bg-gray-100 px-3 py-1 rounded-full text-sm text-gray-600">
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
};

export default TrendingContents;