import React from 'react';
import { ChevronRight, MapPin, Calendar, Users, Heart, ArrowRight } from 'lucide-react';
import WeddingVenueOutdoor1 from '../images/banner/Wedding-Venue-Outdoor-1.jpeg';
import WeddingVenueOutdoor2 from '../images/banner/Wedding-Venue-Outdoor-2.jpeg';
import WeddingVenueOutdoor3 from '../images/banner/Wedding-Venue-Outdoor-3.jpeg';
import Wedding from '../images/banner/Wedding-Hall.webp';
import FAQItem from './FAQItem';

const VenueContentSection = () => {
    
  // const venueTypes = [
  //   {
  //     title: "Elegant Ballrooms",
  //     description: "Perfect for grand celebrations with sophisticated ambiance and modern amenities.",
  //     features: ["High ceilings", "Crystal chandeliers", "Dance floor", "Premium sound system"]
  //   },
  //   {
  //     title: "Rustic Barns",
  //     description: "Charming countryside venues offering a perfect blend of history and romance.",
  //     features: ["Exposed beams", "Natural lighting", "Outdoor space", "Vintage appeal"]
  //   },
  //   {
  //     title: "Garden Venues",
  //     description: "Beautiful outdoor spaces surrounded by nature's own decor.",
  //     features: ["Flowering gardens", "Water features", "Photo opportunities", "Natural scenery"]
  //   }
  // ];


  //These can be loaded from backend
  const faqs = [
    {
      question: "How far in advance should I book my wedding venue?",
      answer: "We recommend booking your venue 12-18 months in advance, especially for popular dates during peak wedding season (May-October). For off-season weddings, 9-12 months ahead is usually sufficient, but earlier booking ensures you get your preferred date and venue."
    },
    {
      question: "What's typically included in a venue rental fee?",
      answer: "Venue rental fees usually include the basic space rental, tables, chairs, and basic linens. Some venues may also include setup/cleanup, parking, security, sound system, and basic decorations. Premium items like specialty lighting, upgraded furniture, or additional rooms may incur extra charges. Always ask for a detailed list of inclusions."
    },
    {
      question: "Can I bring my own vendors or do I have to use preferred vendors?",
      answer: "This varies by venue. Some venues allow any licensed and insured vendor, while others require you to choose from their preferred vendor list. Some may allow outside vendors for an additional fee. It's important to clarify this policy early in your venue search if you have specific vendors in mind."
    },
    {
      question: "What happens if it rains on my wedding day?",
      answer: "Most venues have backup plans for inclement weather, especially if they offer outdoor spaces. Ask about indoor alternatives, covered areas, or tent options. Make sure to discuss the timeline for making weather-related decisions and any additional costs for backup plans."
    },
    {
      question: "Are there time restrictions for music and alcohol service?",
      answer: "Yes, most venues have specific time restrictions due to local noise ordinances and liquor license requirements. Typical end times range from 10 PM to midnight. Some venues may allow you to extend these hours for an additional fee, subject to local regulations."
    },
    {
      question: "What's the payment schedule and cancellation policy?",
      answer: "Standard payment schedules often require a deposit (25-50%) to secure your date, with the remaining balance due in installments or 30 days before the event. Cancellation policies vary widely - some offer partial refunds depending on notice given, while others may be non-refundable after certain dates."
    }
  ];

  return (
    <div className="py-16 px-4 max-w-7xl mx-auto">
      {/* Hero Content Section */}
      <div className="text-center mb-16">
        <h2 className="text3-4xl font-bold mb-4">Types of Venues - Your Perfect Wedding Venue Awaits</h2>
        <p className="text-lg text-gray-600 max-w-2xl mx-auto mb-8">
          Discover everything you need to know about choosing and booking the ideal venue for your special day
        </p>
        <div className="grid md:grid-cols-3 gap-4 mb-8">
          <img
            src={WeddingVenueOutdoor1}
            alt="Elegant ballroom setup"
            className="rounded-lg object-cover w-full h-64"
          />
          <img
            src={WeddingVenueOutdoor2}
            alt="Garden ceremony"
            className="rounded-lg object-cover w-full h-64"
          />
          <img
            src={WeddingVenueOutdoor3}
            alt="Rustic barn venue"
            className="rounded-lg object-cover w-full h-64"
          />
        </div>
      </div>

     

      {/* Featured Venue Spotlight */}
      <div className="bg-rose-50 rounded-lg overflow-hidden mb-16">
        <div className="grid md:grid-cols-2 gap-8 p-8">
          <div>
            <h3 className="text-2xl font-bold mb-4">Venue of the Month</h3>
            <p className="text-gray-600 mb-6">
              Experience the grandeur of our featured venue, combining timeless elegance with modern luxury.
              Perfect for both intimate gatherings and lavish celebrations.
            </p>
            <ul className="space-y-4 mb-6">
              <li className="flex items-start gap-2">
                <MapPin className="w-5 h-5 text-rose-500 mt-1" />
                <p>Prime downtown location with panoramic city views</p>
              </li>
              <li className="flex items-start gap-2">
                <Users className="w-5 h-5 text-rose-500 mt-1" />
                <p>Accommodates up to 300 guests with flexible space options</p>
              </li>
              <li className="flex items-start gap-2">
                <Calendar className="w-5 h-5 text-rose-500 mt-1" />
                <p>Available for all seasons with climate-controlled spaces</p>
              </li>
            </ul>
            <button className="flex items-center gap-2 bg-rose-500 text-white px-6 py-2 rounded-full hover:bg-rose-600 transition-colors">
              Schedule a Tour
              <ArrowRight className="w-4 h-4" />
            </button>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <img
              src="/api/placeholder/300/400"
              alt="Venue interior"
              className="rounded-lg object-cover w-full h-full"
            />
            <div className="grid grid-rows-2 gap-4">
              <img
                src="/api/placeholder/300/200"
                alt="Venue ceremony space"
                className="rounded-lg object-cover w-full h-full"
              />
              <img
                src="/api/placeholder/300/200"
                alt="Venue reception area"
                className="rounded-lg object-cover w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Planning Tips Section */}
      <div className="grid md:grid-cols-2 gap-8 mb-16">
        <img
          src={Wedding}
          alt="Wedding planning"
          className="rounded-lg object-cover w-full h-96"
        />
        <div>
          <h3 className="text-2xl font-bold mb-6 flex items-center gap-2">
            <Heart className="w-6 h-6 text-rose-500" />
            Essential Planning Tips
          </h3>
          <ul className="space-y-4">
            <li className="flex items-start gap-2">
              <ChevronRight className="w-5 h-5 text-rose-500 mt-1 flex-shrink-0" />
              <p>Book your venue 12-18 months in advance to secure your preferred date</p>
            </li>
            <li className="flex items-start gap-2">
              <ChevronRight className="w-5 h-5 text-rose-500 mt-1 flex-shrink-0" />
              <p>Consider guest count flexibility and venue capacity requirements</p>
            </li>
            <li className="flex items-start gap-2">
              <ChevronRight className="w-5 h-5 text-rose-500 mt-1 flex-shrink-0" />
              <p>Review the venue's preferred vendor list and outside vendor policies</p>
            </li>
            <li className="flex items-start gap-2">
              <ChevronRight className="w-5 h-5 text-rose-500 mt-1 flex-shrink-0" />
              <p>Understand the payment schedule and cancellation policy details</p>
            </li>
          </ul>
        </div>
      </div>


      {/* FAQ Section */}

      <div className="mb-16">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Frequently Asked Questions</h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Get answers to common questions about booking and planning your wedding venue
          </p>
        </div>
        
        <div className="grid md:grid-cols-2 gap-8">
          {/* FAQ Image */}
          <div className="relative">
            <img
              src="/api/placeholder/600/800"
              alt="Wedding venue setup"
              className="rounded-lg object-cover w-full h-full"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent rounded-lg flex items-end">
              <div className="p-6 text-white">
                <h3 className="text-2xl font-bold mb-2">Still have questions?</h3>
                <p className="mb-4">Our venue specialists are here to help you plan your perfect day</p>
                <button className="bg-white text-rose-500 px-6 py-2 rounded-full font-semibold hover:bg-gray-100 transition-colors">
                  Contact Us
                </button>
              </div>
            </div>
          </div>
          
          {/* FAQ List */}
          <div className="bg-white rounded-lg shadow-sm border border-gray-100">
            <div className="divide-y divide-gray-100">
              {faqs.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div>
          </div>
        </div>
      </div>







      {/* Call to Action */}
      <div className="text-center bg-gradient-to-r from-rose-100 to-pink-100 rounded-lg p-12 relative overflow-hidden">
        <div className="absolute inset-0 grid grid-cols-3 gap-4 opacity-10">
          <img src={WeddingVenueOutdoor1} alt="Background decoration" className="object-cover" />
          <img src={WeddingVenueOutdoor2} alt="Background decoration" className="object-cover" />
          <img src={WeddingVenueOutdoor3} alt="Background decoration" className="object-cover" />
        </div>
        <div className="relative z-10">
          <h3 className="text-3xl font-bold mb-4">Ready to Find Your Dream Venue?</h3>
          <p className="text-lg text-gray-700 mb-8 max-w-2xl mx-auto">
            Browse our curated selection of venues and take the first step towards your perfect wedding day
          </p>
          <button className="bg-rose-500 text-white px-8 py-3 rounded-full font-semibold hover:bg-rose-600 transition-colors">
            Explore Venues
          </button>
        </div>
      </div>
    </div>
  );
};

export default VenueContentSection;