
// import VenueList from './components/VenueList.js';
import VenueListComponent from './components/VenueListComponent.js';
import Header from './components/Header.js';
import HeroSection from './components/HeroSection.js';
import AboutSection from './components/AboutSection.js';
import Footer from './components/Footer.js';
import VenueDetails from './components/VenueDetails.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import VenueContentSection from './components/VenueContentSection.js';
import TrendingContents from './components/TrendingContents.js';
//import AddVenueForm from './components/AddVenueForm.js';
import React, { useState, useEffect } from "react";
//import LoginSignupPage from './components/LoginSignupPage.js';
//import AddVenue from './components/AddVenue.js';
import './index.css'
import VenueBooking from './components/VenueBooking.jsx';
import AuthPopup from './components/AuthPopup.jsx';

//So App.js acts as root/main component in React.
//On top of this all the components will be holding
//the function App either return an html template or empty that doesn't matter its depends on req.

function App() {

if(window.google.accounts) {
  console.log("Inside the Google.Accounts::")
  window.google.accounts.id.initialize({
        client_id: '385404443939-f6ei5233gd25rse8kbpc5l6vpc0af4i3.apps.googleusercontent.com',
        callback: handleCredentialResponse,
        auto_select: true,
        cancel_on_tap_outside: false
      });
  window.google.accounts.id.prompt();    
}

  // const [isLoggedIn, setIsLoggedIn] = useState(false)
  // console.log("Is UserLogged In ",isLoggedIn);
  // function initializeGoogleSignIn() {
  //   console.log("Google Sign In Initalized");
  // if(window.google && window.google.accounts) {
  //   console.log("Google Identity Services loaded");
  // setTimeout(() => {
  // }, 1000);

  // }else{
  //   console.error('Google Identity Services not loaded');
  // }
  // }

  //Used DOMContentLoaded event to defer execution
  //document.addEventListener('DOMContentLoaded', initializeGoogleSignIn);

  // document.addEventListener('DOMContentLoaded', () => {
  //   const script = document.createElement('script');
  //   script.src = 'https://accounts.google.com/gsi/client';
  //   script.async = true;
  //   script.defer = true;
  //   script.onload = initializeGoogleSignIn;
  //   document.head.appendChild(script);
  // });


  // Callback function to handle the response
  //This will be called once user select the account
  async function handleCredentialResponse(response) {
    // response.credential contains the JWT token
    // Send this to your backend for verification
    console.log("Cred :: ", response.credential);
    const result = await fetch('http://localhost:2018/api/users/auth/google/onetap', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        credential: response.credential
      })
    });
    const data = await result.json();
    console.log("Data JWT Token :: ", data);
    localStorage.setItem('token', data.token);
  }

  // useEffect(() => { 
  //   console.log("Inside the useEffect");
  //   window.google.accounts.id.initialize({
  //     client_id: '385404443939-f6ei5233gd25rse8kbpc5l6vpc0af4i3.apps.googleusercontent.com',
  //     callback: handleCredentialResponse,
  //     auto_select: true,
  //     cancel_on_tap_outside: false
  //   });

  //   // This will display the One Tap prompt
  //   window.google.accounts.id.prompt();

  // }, []);


  // useEffect(() => {

  //   if (!window.google || !window.google.accounts) {
  //     //onError(new Error('Google One Tap script not loaded'));
  //     return;
  //   }

  //   window.google.accounts.id.initialize({
  //     client_id: '385404443939-f6ei5233gd25rse8kbpc5l6vpc0af4i3.apps.googleusercontent.com',
  //     //callback: onSuccess,
  //     auto_select: false,
  //     cancel_on_tap_outside: true
  //   });

  //   window.google.accounts.id.prompt();

  // //   if(window.google && window.google.accounts) {
  // //   window.google.accounts.id.initialize({
  // //     client_id: '385404443939-f6ei5233gd25rse8kbpc5l6vpc0af4i3.apps.googleusercontent.com',
  // //     callback: handleCredentialResponse,
  // //     auto_select: true,
  // //     cancel_on_tap_outside: false
  // //   });


  // //   window.google.accounts.id.prompt();
  // // }
  // }, []);


  return (

    <Router>
      <Header />
      <Routes>

        {/* {isLoggedIn ? (
          <Route path='/add-venue' element={<> <AddVenueForm isLoggedIn={isLoggedIn} />  </>} />
        ) : (
          <Route path='/login' element={<LoginSignupPage setIsLoggedIn={setIsLoggedIn} />} />
        )} */}

        <Route path="/" element={<>  <HeroSection /> <VenueListComponent /> <TrendingContents /> <VenueContentSection /> <AboutSection /> <Footer />  </>} />
        {/* <Route path='/login' element={<LoginSignupPage setIsLoggedIn={setIsLoggedIn} />} /> */}
        {/* <Route path='/add-venue' element={<> <HeroSection /> <AddVenueForm isLoggedIn={isLoggedIn} />  </>} /> */}

        <Route path="/venue/:slug/:id" element={<>  <HeroSection /> <VenueDetails /> <TrendingContents /> <VenueContentSection /> <AboutSection /> <Footer />  </>} />
        <Route path="/venue-booking" element={<>  <HeroSection /> <VenueBooking /> <AboutSection /> <Footer />  </>} />
        <Route path="/signin" element={<>  <AuthPopup />  </>} />
        <Route path="/signup" element={<>  <AuthPopup />  </>} />

        <Route path="/wedding-halls" element={<>   <VenueListComponent /> <TrendingContents /> <VenueContentSection /> <AboutSection /> <Footer />  </>} />
        <Route path="/party-hall" element={<>   <VenueListComponent /> <TrendingContents /> <VenueContentSection /> <AboutSection /> <Footer />  </>} />
        <Route path="/conventional-halls" element={<>   <VenueListComponent /> <TrendingContents /> <VenueContentSection /> <AboutSection /> <Footer />  </>} />
        <Route path="/marriage-halls" element={<>   <VenueListComponent /> <TrendingContents /> <VenueContentSection /> <AboutSection /> <Footer />  </>} />
        <Route path="/blogs" element={<>  <TrendingContents /> <VenueContentSection /> <AboutSection /> <Footer />  </>} />
        <Route path="/venues" element={<>   <VenueListComponent /> <TrendingContents /> <VenueContentSection /> <AboutSection /> <Footer />  </>} />

      </Routes>
    </Router>

  );

}

export default App;