import React from 'react';
import styled from 'styled-components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const VenueCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const VenueImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const VenueContent = styled.div`
  padding: 15px;
  background-color: #f5f5f5;
`;

const VenueName = styled.h3`
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: bold;
`;

const VenueLocation = styled.p`
  color: #888;
  margin-bottom: 5px;
`;

const VenueAmenities = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const VenuePrice = styled.p`
  font-weight: bold;
  margin-top: 10px;
`;

const BookButton = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
`;

const VenueCard = ({ venue,className = '' }) => {

  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const baseUrl = 'http://localhost:2017/venueImages';
  
  const createSlug = async (name,location) => {

    console.log("Incoming Name :: ",name," Location :: ",location);
    let slug = name+"-"+location;
    slug=slug.toLowerCase().replace(/\s+/g, '-');
    slug=slug.replace(/[^a-zA-Z0-9-]/g, '');  // /g for global replacement
    console.log("Final URL:: ",slug);
    return slug;   
  
  }

  const handleClick =  async () => {
    console.log("Venue Clicked",venue.name);
    console.log("Venue Images List",venue.imagesUrl);
    const slug =  await createSlug(venue.name,venue.address.city);

    console.log("Slug :: ",slug);

    navigate(`/venue/${slug}/${venue.id}`);

  }

  return (
    <VenueCardContainer className="w-full 
        max-w-[300px] 
        sm:max-w-none 
        transform 
        transition-all 
        duration-300 
        hover:-translate-y-1 
        hover:shadow-xl"
     onClick={handleClick} onMouseEnter={()=> setIsHovered(true)} onMouseLeave={()=> setIsHovered(false)} >

      <VenueImage src={`${baseUrl}/${venue.imageUrl}`} alt={`${venue.name} in ${venue.location}`} 
      title={`${venue.name} in ${venue.location}`} loading="lazy" className={`${isHovered ? 'opacity-75' : 'opacity-100'}`}/>
      
      <VenueContent className={`${isHovered ? 'opacity-75' : 'opacity-100'}`}>
        <VenueName>{venue.name}</VenueName>
        {/* <VenueLocation>{venue.address.city}</VenueLocation> */}
        <VenuePrice>Offer price Rs. {venue.pricePerHour}/Hour</VenuePrice>
        
        <VenueAmenities>
          {venue.amenities.map((amenity) => (
            <span key={amenity} className="ml-1 text-xs bg-gray-500 text-white px-1 rounded">{amenity}</span>
          ))}
        </VenueAmenities>
        <BookButton>Book Now</BookButton>
      </VenueContent>
    </VenueCardContainer>
  );
};

export default VenueCard;
