import { Underline } from 'lucide-react';
import React from 'react';
import { Link } from 'react-router-dom'; 

function AboutSection() {

  const destinations = {
    "Destination Cities": [
      "Delhi",
  "Mumbai",
  "Bengaluru", 
  "Chennai", 
  "Kolkata", 
  "Hyderabad",
  "Pune",
  "Ahmedabad", 
  "Surat", 
  "Jaipur", 
  "Lucknow", 
  "Kanpur", 
  "Nagpur", 
  "Indore", 
  "Coimbatore", 
  "Kochi", 
  "Chandigarh"],
  "India and States": [

  { name: "Andhra Pradesh", url: "https://tourism.ap.gov.in/" },
  { name: "Goa", url: "https://goa-tourism.com/" },
  { name: "Karnataka", url: "https://www.karnatakatourism.org/" },
  { name: "Kerala", url: "http://www.keralatourism.org" },
  { name: "Maharashtra", url: "http://www.maharashtratourism.gov.in/" },
  { name: "Puducherry", url: "http://www.pondytourism.in/" } 
    ],
    "Karnataka": [
      "Bagalkot",
  "Bengaluru Rural",
  "Bengaluru Urban",
  "Belagavi",
  "Ballari",
  "Bidar",
  "Vijayapura",
  "Chamarajanagar",
  "Chikkaballapura",
  "Chikkamagaluru",
  "Chitradurga",
  "Dakshina Kannada",
  "Davanagere",
  "Dharwad",
  "Gadag",
  "Kalaburagi",
  "Hassan",
  "Haveri",
  "Kodagu"
    ],
    "In Namma Bengaluru": [
     "Indiranagar",
  "Koramangala",
  "HSR Layout",
  "Whitefield",
  "Electronic City",
  "Jayanagar",
  "JP Nagar",
  "Bannerghatta Road",
  "Marathahalli",
  "Bellandur",
  "Sarjapur Road",
  "MG Road",
  "Brigade Road",
  "Ulsoor",
  "Rajajinagar",
  "Basavanagudi",
  "Yeshwantpur",
  "Hebbal",
  "Domlur",
  "Old Airport Road"
    ],
    "RiteVenue Picks": [
      "Asia Apartments",
      "Bangkok Apartments",
      "Kuala Lumpur Apartments",
      "Manila Apartments",
      "Osaka Apartments",
      "Pattaya Apartments",
      "Tokyo Apartments",
      "Asia Bungalows",
      "Bali Bungalows",
      "Koh Kood Bungalows",
      "Koh Lanta Bungalows",
      "Koh Phangan Bungalows",
      "Koh Samet Bungalows"
    ]
  };

  const states = [
    
  ];

  return (

      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8">
          {Object.entries(destinations).map(([categories,values])=>(
            <div key={categories} className="space-y-4">
              <h6 className="text-sm font-bold">{categories}</h6>
              <ul className="space-y-2">
                 {values.map((item,index)=>( 
                   <li key={index} className="text-sm" style={{lineHeight: '0.63rem'}}> 
                   {item.url ? (
                   <a  href={item.url} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'underline'}}>

                   <span  style={{fontSize:'0.7rem'}} >
                    Wedding Halls in {item.name}</span> <span className="ml-1 text-xs bg-red-500 text-white px-1 rounded">new!</span>
                   </a>
                   ):(<span style={{fontSize:'0.7rem'}}> Wedding Halls in {item}</span>)
                  }
                   </li>
                 ))}
              </ul>
            </div>
          ))}
        </div>
      </div>




    

  );
}

export default AboutSection;