import React, { useState,useEffect } from 'react';
import { Search, MapPin } from 'lucide-react';
import backgroundImage1 from '../images/banner/Wedding-Hall.webp';


const HeroSection = () => {
  const [location, setLocation] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [checkIn, setCheckIn] = useState('');
  const [checkOut, setCheckOut] = useState('');
  const [guests, setGuests] = useState('Select');


  const indianCities = [
    {
      id: 1,
      city: "Mumbai",
      state: "Maharashtra",
      type: "Metropolis",
      lat: "19.0760",
      lon: "72.8777",
      population: "20.4 million",
      pinCode: "400001"
    },
    {
      id: 2,
      city: "Delhi",
      state: "Delhi",
      type: "Capital",
      lat: "28.6139",
      lon: "77.2090",
      population: "30.3 million",
      pinCode: "110001"
    },
    {
      id: 3,
      city: "Bangalore",
      state: "Karnataka",
      type: "Tech Hub",
      lat: "12.9716",
      lon: "77.5946",
      population: "12.3 million",
      pinCode: "560001"
    },
    {
      id: 4,
      city: "Hyderabad",
      state: "Telangana",
      type: "Tech Hub",
      lat: "17.3850",
      lon: "78.4867",
      population: "9.7 million",
      pinCode: "500001"
    },
    {
      id: 5,
      city: "Chennai",
      state: "Tamil Nadu",
      type: "Metropolis",
      lat: "13.0827",
      lon: "80.2707",
      population: "10.9 million",
      pinCode: "600001"
    },
    {
      id: 6,
      city: "Kolkata",
      state: "West Bengal",
      type: "Metropolis",
      lat: "22.5726",
      lon: "88.3639",
      population: "14.9 million",
      pinCode: "700001"
    },
    {
      id: 7,
      city: "Pune",
      state: "Maharashtra",
      type: "Educational Hub",
      lat: "18.5204",
      lon: "73.8567",
      population: "6.6 million",
      pinCode: "411001"
    },
    {
      id: 8,
      city: "Ahmedabad",
      state: "Gujarat",
      type: "Industrial",
      lat: "23.0225",
      lon: "72.5714",
      population: "7.2 million",
      pinCode: "380001"
    },
    {
      id: 9,
      city: "Jaipur",
      state: "Rajasthan",
      type: "Tourism",
      lat: "26.9124",
      lon: "75.7873",
      population: "3.1 million",
      pinCode: "302001"
    },
    {
      id: 10,
      city: "Lucknow",
      state: "Uttar Pradesh",
      type: "Cultural",
      lat: "26.8467",
      lon: "80.9462",
      population: "3.4 million",
      pinCode: "226001"
    }
  ];

  useEffect(() => {

    searchLocations(location);


  },[location])


  const searchLocations =  (location) => {

    console.log("Inside the searchLocations function");
    console.log("Location is: ", location);

    if(location.trim() === ""){
      console.log("Location is empty");
      setSuggestions([]);
      return;
    }

    const filteredLocation=indianCities.filter((l) => {
      return l.city.toLowerCase().includes(location.toLowerCase()) || 
      l.state.toLowerCase().includes(location.toLowerCase());

    });

    setSuggestions(filteredLocation);


  }

  const handleSelect = (e) => {

    console.log("Inside the handleSelect function");
    setSuggestions([]);
    setLocation(e.city);
    


    
  };

  return (
    <div className="relative h-[800px] md:h-[400px] bg-cover bg-center flex items-center justify-center" loading="lazy" style={{backgroundImage: `url(${backgroundImage1})`}}>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative z-10 text-white text-center px-4">
        <br></br>
        <br></br>
        <br></br>
        {/* <img src="AWYs27wYOm2w9_WR-k8KQsuwNMZS-CFazkLBGvF3XcXJ1nPlsJ6dlQGNGwn7zhtUQcLssylvqKjgBv5poSSr7qaIdHM73IUF7kdxM_49XBsfTxVBi-KaI7D37Z8Pf3LNN4MBUwGDg0sbvnZsdku_6eNGQDkTtBSS6-jW5O-ovrXhDNBWngWq" alt="RiteVenue" className="w-40 mx-auto" /> */}
        <h1 className="text-4xl md:text-5xl font-bold mb-4">Find Your Perfect Venue for Every Occasion | Find the Best Venues in Bangalore</h1>
        <p className="text-xl mb-8"> RiteVenue - Yes! You are at the Right place</p>

        
        <br></br>
        <br></br>
        <br></br>
      
        <form onSubmit="" className="bg-white rounded-lg shadow-lg overflow-hidden max-w-4xl mx-auto">
          <div className="flex flex-col md:flex-row">

            <div className="flex-1 border-b md:border-b-0 md:border-r border-gray-300">
              <input
                type="text"
                placeholder="Where to?"
                className="w-full px-4 py-3 text-gray-700 focus:outline-none"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>
            
            {suggestions.length > 0 && (
          <ul className="absolute bg-white mt-1 border rounded-lg shadow-lg max-h-60 overflow-auto z-10 top-13" style={{ marginTop: '3.25rem' }}>
            {suggestions.map((location) => (
              <li
                key={location.id}
                onClick={() => handleSelect(location)}
                className="p-3 hover:bg-gray-50 cursor-pointer border-b last:border-b-0"
              >
                <div className="flex items-start gap-2">
                  <MapPin className="h-5 w-5 text-gray-400 mt-1 flex-shrink-0" />
                  <div>
                    <div className="font-medium text-gray-600">{location.city}</div>
                    <div className="text-sm text-gray-600">
                      {location.state} - {location.type}
                    </div>
                    <div className="text-xs text-gray-500">
                      Available Venues: {location.population}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}

            <div className="flex-1 border-b md:border-b-0 md:border-r border-gray-300">
              <input
                type="date"
                placeholder="Check in"
                className="w-full px-4 py-3 text-gray-700 focus:outline-none"
                value={checkIn}
                onChange={(e) => setCheckIn(e.target.value)}
              />
            </div>
            <div className="flex-1 border-b md:border-b-0 md:border-r border-gray-300">
              <input
                type="date"
                placeholder="Check out"
                className="w-full px-4 py-3 text-gray-700 focus:outline-none"
                value={checkOut}
                onChange={(e) => setCheckOut(e.target.value)}
              />
            </div>
            <div className="flex-1 border-b md:border-b-0 md:border-r border-gray-300">
              <select
                className="w-full px-4 py-3 text-gray-700 focus:outline-none"
                value={guests}
                onChange={(e) => setGuests(e.target.value)}
              >
                <option>0 - 200 Guests</option>
                <option>200 - 500 Guests</option>
                <option>500 - 800 Guests</option>
                <option>800 - 1200 Guests</option>
                <option>1200 Guests above</option>
              </select>
            </div>
            <div className="flex-none">
              <button type="submit" className="w-full h-full px-6 py-3 bg-blue-600 text-white font-semibold hover:bg-blue-700 transition duration-300">
                Search
              </button>
            </div>
          </div>
        </form>
        <br></br>
      </div>
    </div>
  );
};

export default HeroSection;




