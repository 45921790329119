import React, { useEffect,useContext } from "react";
import { useState } from "react";
import axios from "axios";
import { MapPin, Calendar, Users } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
//import { Button } from '@/components/ui/button';
import AuthPopup from './AuthPopup';

const VenueDetails =() => {

  const navigate = useNavigate();

  const baseUrl = 'http://localhost:2017/venueImages/';
  const [venue,setVenue]= useState(null)
  const [selectedImage, setSelectedImage] = useState(null);

  //Auth Flows
  const [showAuthPopup, setShowAuthPopup] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
  

    const getVenueIdByPathURL = () => { 

        const pathURL = window.location.pathname;
        console.log("Path URL ::",pathURL);
        const pathParts = pathURL.split('/');
        const venueId = pathParts[pathParts.length - 1];
        return venueId;
    }

    useEffect(() => {
      
    const fetchVenueDetails = async () => {

            const venueId=getVenueIdByPathURL();
            axios.get(`http://localhost:2017/api/venues/${venueId}`)
            .then((response) => {
             // Handle successful response
             console.log("Debug :: Venue Details ",response.data);
             setVenue(response.data)
              setSelectedImage(response.data.imageUrl);
            }).catch((error)=>{
                console.log("Error :: During the GetVenues Call ",error)
               }).finally(()=>{
                     console.log("For Test added! Finally Block")
                })

                console.log("Venue Details ",venue);
                
    }

    fetchVenueDetails();
    console.log("Debug :: Venue Details ",venue);

    const token = localStorage.getItem('token');
    if (token) {
      console.log("User is Authenticated  Token ::",token);
      setIsAuthenticated(true); 
    }

    if(!isAuthenticated){
      window.google.accounts.id.initialize({
        client_id: '385404443939-f6ei5233gd25rse8kbpc5l6vpc0af4i3.apps.googleusercontent.com',
        callback: handleCredentialResponse,
        auto_select: true,
        //cancel_on_tap_outside: false
      });
    }

    window.google.accounts.id.prompt();

    async function handleCredentialResponse(response) {
      // response.credential contains the JWT token
      // Send this to your backend for verification
      console.log("Cred :: ", response.credential);
      const result = await fetch('http://localhost:2018/api/users/auth/google/onetap', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          credential: response.credential
        })
      });
      const data = await result.json();
      console.log("Data JWT Token :: ", data); 
      localStorage.setItem('token', data.token);
    }



    
    },[]);

    const handleBooking = async () =>{

      console.log("Inside Handle Booking :: ",isAuthenticated);

      // Check if user is authenticated
    if (!isAuthenticated) {
      setShowAuthPopup(true);

    } else {
      // Proceed to booking
      navigate('/venue-booking');
    }







    }

    const handleAuthSuccess = () => {
      // After successful authentication, redirect to booking form
      navigate('/booking-form');
    };

    //async function to handle Google Sign In 
    const handleGoogleSignIn = async () => {
      try {
        // Initialize Google OAuth client
        const client = await window.google.accounts.oauth2.initCodeClient({
          client_id: '385404443939-f6ei5233gd25rse8kbpc5l6vpc0af4i3.apps.googleusercontent.com',
          scope:"https://www.googleapis.com/auth/userinfo.profile",
          callback: (response) => {
            console.log("Google Sign-in Response :: ",response);
            if (response.code) {

              console.log("Google Sign-in Response Code :: ",response.code);
              // Send the code to your backend to complete authentication
              handleAuthSuccess();
            }
          },
        });
  
        client.requestCode();
      } catch (error) {
        console.error('Google Sign-in Error:', error);
        //setIsLoading(false);
      }
    };

    if (!venue) return <div>Loading...</div>;

    // || [] is used to handle the case when there are no images
    //  [ code ] -> to create a list
    // venue.imageUrl -> is the main image and first image in the list
    // venue.imagesUrl -> is the list of images
    // ... -> spread operator to combine the two lists
    const allImages=[venue.imageUrl, ...(venue.imagesUrl || [])];

    return (
        <div className="max-w-6xl mx-auto px-4 py-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Main Image and Gallery */}


          
            <div className="space-y-4">
              <img
                src={baseUrl+selectedImage || "/api/placeholder/600/400"}
                alt={venue.name}
                className="w-full h-96 object-cover rounded-lg"
                loading="lazy"
              />
              <div className="grid grid-cols-3 gap-2">
                {allImages?.map((image, index) => (
                  <img
                    key={index}
                    src={baseUrl+image || "/api/placeholder/200/200"}
                    alt={`${baseUrl+image}`}
                    className="w-full h-32 object-cover rounded-lg"
                    onClick={() => setSelectedImage(image)}
                    loading="lazy"
                  />
                ))}
              </div>
            </div>
    



            {/* Venue Information */}
            <div className="space-y-6">
              <div>
                <h1 className="text-3xl font-bold mb-2">{venue.name}</h1>
                <div className="flex items-center text-gray-600 mb-4">
                  <MapPin className="w-5 h-5 mr-2" />
                  {/* <span>{venue.address.city}</span> */}
                </div>
              </div>
    
              <div className="grid grid-cols-2 gap-4">
                <div className="bg-gray-50 p-4 rounded-lg">
                  <div className="flex items-center mb-2">
                    <Users className="w-5 h-5 mr-2" />
                    <span className="font-semibold">Capacity</span>
                  </div>
                  <p>{venue.capacity} guests</p>
                </div>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <div className="flex items-center mb-2">
                    <Calendar className="w-5 h-5 mr-2" />
                    <span className="font-semibold">Availability</span>
                  </div>
                  <p>{venue.availability}</p>
                </div>
              </div>
    
              <div>
                <h2 className="text-xl font-semibold mb-2">Description</h2>
                <p className="text-gray-600">{venue.description}</p>
              </div>
    
              <div>
                <h2 className="text-xl font-semibold mb-2">Amenities</h2>
                <div className="grid grid-cols-2 gap-2">
                  {venue.amenities?.map((amenity, index) => (
                    <div key={index} className="flex items-center">
                      <span className="w-2 h-2 bg-blue-500 rounded-full mr-2" />
                      <span>{amenity}</span>
                    </div>
                  ))}
                </div>
              </div>
    
              <div className="pt-4">
                <div className="text-2xl font-bold mb-4">${venue.pricePerHour}/hr</div>
              
<div className="flex space-x-4"> 
  <button
    onClick={handleBooking}
    className="w-1/2 bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-md transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
  >
    Book Now
  </button>

{showAuthPopup && !isAuthenticated && (
  <AuthPopup
        isOpen={showAuthPopup}
        onClose={() => setShowAuthPopup(false)}
        onGoogleSignIn={handleGoogleSignIn}
        // onEmailSignIn={handleEmailSignIn}
        // onGuestBooking={handleGuestBooking}
      />
)}

  <button
    // onClick={handleBooking}
    className="w-1/2 bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-md transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
  >
    Enquire Now
  </button>
</div>
              </div>
            </div>
          </div>
        </div>
      );


}
export default VenueDetails;