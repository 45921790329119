import React, { useState, useEffect } from 'react';
import { Search, MapPin, DollarSign, SlidersHorizontal, X, ChevronDown, ChevronUp, Filter } from 'lucide-react';
import VenueCard from './VenueCard';
import axios from "axios";

const VenueListComponent = () => {
  const [sortBy, setSortBy] = useState('');
  const [venues, setVenues] = useState([]);
  const [filters, setFilters] = useState({
    priceRange: null,
    location: '',
    amenities: []
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedSections, setExpandedSections] = useState({
    price: true,
    location: true,
    amenities: true
  });
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://localhost:2017/api/venues/all");
        setVenues(response.data);
      } catch (error) {
        console.error("Error fetching venues:", error);
      }
    };

    fetchData();
  }, []);

  // Filter and sort venues logic remains the same

  console.log("Venues::",venues);

  // const filteredVenues=venues

  const filteredVenues = venues
    .filter(venue => {
      const matchesSearch = venue.name.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesPrice = !filters.priceRange || venue.priceRange <= filters.priceRange;
      const matchesLocation = !filters.location || venue.address.city.includes(filters.location);
      const matchesAmenities = filters.amenities.length === 0 || 
        filters.amenities.every(amenity => venue.amenities.includes(amenity));

      return matchesSearch && matchesPrice && matchesLocation && matchesAmenities;

    })
    .sort((a, b) => {
      switch (sortBy) {
        case 'price-asc':
          return a.price - b.price;
        case 'price-desc':
          return b.price - a.price;
        case 'location':
          return a.address.city.localeCompare(b.address.city);
        default:
          return 0;
      }
    });

  // Filter sections content
  const FilterSections = () => (
    <>
      {/* Price Range Section */}
      <div className="mb-6 bg-gradient-to-r from-slate-900 to-slate-800 rounded-xl p-4">
        <button 
          className="flex items-center justify-between w-full text-lg font-medium mb-4 text-white hover:text-blue-400 transition-colors"
          onClick={() => toggleSection('price')}
        >
          <span className="flex items-center gap-2">
            <DollarSign size={20} className="text-blue-400" />
            Price Range
          </span>
          {expandedSections.price ? 
            <ChevronUp size={20} className="text-blue-400" /> : 
            <ChevronDown size={20} className="text-blue-400" />
          }
        </button>
        
        {expandedSections.price && (
          <div className="space-y-4">
            <div className="relative pt-1">
              <input
                type="range"
                min="0"
                max="1000"
                step="100"
                value={filters.priceRange || 0}
                onChange={(e) => setFilters({...filters, priceRange: Number(e.target.value)})}
                className="w-full h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer 
                  [&::-webkit-slider-thumb]:appearance-none 
                  [&::-webkit-slider-thumb]:w-4 
                  [&::-webkit-slider-thumb]:h-4 
                  [&::-webkit-slider-thumb]:bg-blue-400 
                  [&::-webkit-slider-thumb]:rounded-full 
                  [&::-webkit-slider-thumb]:cursor-pointer
                  [&::-webkit-slider-thumb]:shadow-lg
                  [&::-webkit-slider-thumb]:shadow-blue-500/50
                  [&::-webkit-slider-thumb]:hover:bg-blue-300
                  [&::-webkit-slider-thumb]:transition-colors"
              />
              <div className="relative mt-4">
                <div className="flex justify-between text-xs text-gray-400">
                  <span>$0</span>
                  <span>$250</span>
                  <span>$500</span>
                  <span>$750</span>
                  <span>$1000</span>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <div className="bg-slate-700 px-4 py-2 rounded-full">
                <span className="text-blue-400 font-medium">
                  Selected: ${filters.priceRange || 1000}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Location Section */}
      <div className="mb-6 bg-gradient-to-r from-slate-900 to-slate-800 rounded-xl p-4">
        
        <button 
          className="flex items-center justify-between w-full text-lg font-medium mb-3 text-white hover:text-blue-400 transition-colors"
          onClick={() => toggleSection('location')}
        >
          <span className="flex items-center gap-2">
            <MapPin size={20} className="text-blue-400" />
            Location
          </span>
          {expandedSections.location ? <ChevronUp size={20} className="text-blue-400" /> : <ChevronDown size={20} className="text-blue-400" />}
        </button>

        {expandedSections.location && (
          <input
            type="text"
            placeholder="Enter location..."
            value={filters.location}
            onChange={(e) => setFilters({...filters, location: e.target.value})}
            className="w-full px-4 py-2 rounded-xl bg-white/5 border border-gray-200/20 text-white"
          />
        )}
      </div>

      {/* Amenities Section */}
      <div className="mb-6 bg-gradient-to-r from-slate-900 to-slate-800 rounded-xl p-4">
        <button 
          className="flex items-center justify-between w-full text-lg font-medium mb-3 text-white hover:text-blue-400 transition-colors"
          onClick={() => toggleSection('amenities')}
        >
          <span className="flex items-center gap-2">
            <SlidersHorizontal size={20} className="text-blue-400" />
            Amenities
          </span>
          {expandedSections.amenities ? <ChevronUp size={20} className="text-blue-400" /> : <ChevronDown size={20} className="text-blue-400" />}
        </button>
        {expandedSections.amenities && (
          <div className="flex flex-wrap gap-2">
            {['Parking', 'WiFi', 'Catering', 'AV Equipment'].map(amenity => (
              <button
                key={amenity}
                onClick={() => {
                  const newAmenities = filters.amenities.includes(amenity)
                    ? filters.amenities.filter(a => a !== amenity)
                    : [...filters.amenities, amenity];
                  setFilters({...filters, amenities: newAmenities});
                }}
                className={`px-4 py-2 rounded-xl text-sm transition-all ${
                  filters.amenities.includes(amenity)
                    ? 'bg-blue-500 text-white'
                    : 'bg-white/5 border border-gray-200/20 hover:bg-white/10 text-white'
                }`}
              >
                {amenity}
              </button>
            ))}
          </div>
        )}
      </div>
    </>
  );

    return (
      <div className="flex flex-col md:flex-row gap-6 w-full max-w-7xl mx-auto p-6">
        {/* Desktop Filters Sidebar */}
        <div className="hidden lg:block w-64 flex-shrink-0">
          <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-4 shadow-lg sticky top-6">
            <h2 className="text-xl font-semibold mb-6 text-white">Filters</h2>
            <FilterSections />
          </div>
        </div>
  
        {/* Mobile Filter Button */}
        <div className="md:hidden fixed bottom-4 right-4 z-50">
          <button
            onClick={() => setIsFilterDrawerOpen(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded-full shadow-lg flex items-center gap-2 hover:bg-blue-600 transition-colors"
          >
            <Filter size={20} />
            <span>Filters</span>
          </button>
        </div>
  
        {/* Mobile Filter Drawer */}
        {isFilterDrawerOpen && (
          <div className="md:hidden">
            {/* Overlay */}
            <div 
              className="fixed inset-0 bg-black/50 z-50"
              onClick={() => setIsFilterDrawerOpen(false)}
            />
            {/* Drawer */}
            <div className="fixed inset-y-0 right-0 w-full max-w-xs bg-slate-900 shadow-xl z-50 overflow-y-auto">
              <div className="p-4">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-xl font-bold text-white">Filters</h2>
                  <button 
                    onClick={() => setIsFilterDrawerOpen(false)}
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    <X size={24} />
                  </button>
                </div>
                <FilterSections />
              </div>
            </div>
          </div>
        )}

      {/* Main Content Area */}
      <div className="flex-1">
        {/* Search and Sort Bar */}
        <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-4 mb-6 shadow-lg">
          <div className="flex gap-4 items-center">
            <div className="flex-1 relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="text"
                placeholder="Search venues..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 rounded-xl bg-black/65 border border-gray-200/20 focus:ring-2 focus:ring-blue-500 outline-none text-white"
              />
            </div>
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="px-4 py-2 rounded-xl bg-black/55 border border-gray-200/20 outline-none text-white"
            >
              <option value="">Sort by</option>
              <option value="price-asc">Price: Low to High</option>
              <option value="price-desc">Price: High to Low</option>
              <option value="location">Location</option>
            </select>
          </div>
        </div>

        {/* Venue Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {filteredVenues.map((venue) => (
            <div key={venue.id} className="venue-card-wrapper w-full flex justify-center">
              <VenueCard venue={venue} className="w-full max-w-[300px] sm:max-w-none"  />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VenueListComponent;