import React, { useState } from 'react';
import { ChevronRight, MapPin, Calendar, Users, Heart, ArrowRight, Plus, Minus } from 'lucide-react';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 last:border-0">
      <button
        className="w-full py-6 flex justify-between items-center text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h4 className="font-semibold text-lg pr-8">{question}</h4>
        {isOpen ? (
          <Minus className="w-5 h-5 text-rose-500 flex-shrink-0" />
        ) : (
          <Plus className="w-5 h-5 text-rose-500 flex-shrink-0" />
        )}
      </button>
      {isOpen && (
        <div className="pb-6">
          <p className="text-gray-600">{answer}</p>
        </div>
      )}
    </div>
  );
};
export default FAQItem;