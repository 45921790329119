import { useState } from 'react';
import { Menu, X, PlusCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom'

const Header = ({ isLoggedIn }) => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const navigate = useNavigate()

  //When User Clicked on AddVenue
  const handleAddVenueClick = (e) => {
    e.preventDefault();
    if (!isLoggedIn) {
      navigate('/login');
    } else {
      window.open('/add-venue', '_blank');
    }
  };


  



  return (
    <>
      {/* <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet" /> */}


      <header className="bg-white shadow-md">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between py-2">
            {/* Logo */}
            <div className="text-blue-600 font-bold text-xl sm:text-2xl">RiteVenue</div>



            {/* Desktop Navigation */}
            <nav className="hidden lg:flex space-x-4">
              <a href="/wedding-halls" className="text-gray-700 hover:text-blue-600"> Wedding Halls</a>
              <a href="/conventional-halls" className="text-gray-700 hover:text-blue-600"> Conventional Halls</a>
              <a href="/marriage-halls" className="text-gray-700 hover:text-blue-600"> Marriage Halls</a>
              <a href="/blogs" className="text-gray-700 hover:text-blue-600"> Blogs</a>
              <a href="/party-hall" className="text-gray-700 hover:text-blue-600 flex items-center">
                Party Hall
              </a>
              <a href="#" className="text-gray-700 hover:text-blue-600 flex items-center">
                Offers & Deals in RiteVenue
              </a>

              {/* <a href="/add-venue" target="_blank" rel="noopener noreferrer" onClick={handleAddVenueClick}
              className="text-green-600 hover:text-green-800 flex items-center">
              <PlusCircle className="mr-1" size={18} />Add Venue</a> */}

            </nav>

            {/* Desktop Right side actions */}
            <div className="hidden lg:flex items-center space-x-4">
              {/* <a href="/signin" className="text-gray-700 hover:text-blue-600">Sign in</a>
              <a href="/signup" className="text-blue-600 border border-blue-600 px-3 py-1 rounded-md text-sm">Create account</a> */}
            </div>

            {/* Mobile menu button */}
            <button onClick={toggleMenu} className="lg:hidden text-gray-700">
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>

        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="lg:hidden bg-white border-t">
            <div className="container mx-auto px-4 py-2">
              <nav className="flex flex-col space-y-2">
                <a href="/wedding-halls" className="text-gray-700 hover:text-blue-600">Wedding Halls</a>
                <a href="/conventional-hall" className="text-gray-700 hover:text-blue-600">Conventional Halls</a>
                <a href="/marriage-hall" className="text-gray-700 hover:text-blue-600">Marriage Halls</a>
                <a href="/blogs" className="text-gray-700 hover:text-blue-600">Blogs</a>
                <a href="party-hall" className="text-gray-700 hover:text-blue-600 flex items-center">
                  Party Hall
                </a>
                <a href="/#" className="text-gray-700 hover:text-blue-600 flex items-center">
                  Offers & Deals in RiteVenue 
                </a>
                {/* <a href="/signin" className="text-gray-700 hover:text-blue-600">Sign in</a>
                <a href="/signup"  className="text-blue-600">Create account</a> */}
              </nav>
            </div>
          </div>
        )}
      </header>
    </>
  );
};

export default Header;