import React, { useState } from 'react';

const VenueBooking = () => {
  const [isGuest, setIsGuest] = useState(true);
  const [formData, setFormData] = useState({
    eventName: '',
    date: '',
    startTime: '',
    endTime: '',
    attendees: '',
    venueType: '',
    additionalRequirements: '',
    name: '',
    email: '',
    phone: ''
  });

  const handleChange = (e) => {
    console.log("e.target", e.target);
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('your-backend-url/api/venue-booking', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });
      
      if (response.ok) {
        alert('Booking request submitted successfully!');
        setFormData({
          eventName: '',
          date: '',
          startTime: '',
          endTime: '',
          attendees: '',
          venueType: '',
          additionalRequirements: '',
          name: '',
          email: '',
          phone: ''
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit booking request. Please try again.');
    }
  };

  // Common input styles for better reusability
  const inputClasses = "mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 py-3 px-4 h-12 text-base";
  const labelClasses = "block text-sm font-medium text-gray-700 mb-1";
  const sectionClasses = "bg-gray-50 p-8 rounded-xl";

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
          <div className="px-8 py-10">
            <h2 className="text-3xl font-bold text-center text-gray-800 mb-10">
              Book Your Perfect Venue
            </h2>
            
            <form onSubmit={handleSubmit} className="space-y-8">
              {/* Event Details Section */}
              <div className="space-y-6">
                <div className={sectionClasses}>
                  <h3 className="text-xl font-semibold text-gray-800 mb-6">Event Details</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label className={labelClasses}>
                        Event Name
                      </label>
                      <input
                        type="text"
                        name="eventName"
                        value={formData.eventName}
                        onChange={handleChange}
                        className={inputClasses}
                        placeholder='Name it anything you like'
                        required
                      />
                    </div>
                   
                    <div>
                      <label className={labelClasses}>
                        Start Time
                      </label>
                      <input
                        type="time"
                        name="startTime"
                        value={formData.startTime}
                        onChange={handleChange}
                        className={inputClasses}
                        required
                      />
                    </div>
                    <div>
                      <label className={labelClasses}>
                        End Time
                      </label>
                      <input
                        type="time"
                        name="endTime"
                        value={formData.endTime}
                        onChange={handleChange}
                        className={inputClasses}
                        required
                      />
                    </div>
                  </div>
                </div>

                {/* Venue Requirements Section */}
                <div className={sectionClasses}>
                  <h3 className="text-xl font-semibold text-gray-800 mb-6">Venue Requirements</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label className={labelClasses}>
                        Number of Attendees
                      </label>
                      <input
                        type="number"
                        name="attendees"
                        value={formData.attendees}
                        onChange={handleChange}
                        className={inputClasses}
                        placeholder='Avg Count'
                        required
                      />
                    </div>
                    
                    <div className="md:col-span-2">
                      <label className={labelClasses}>
                        Additional Requirements
                      </label>
                      <textarea
                        name="additionalRequirements"
                        value={formData.additionalRequirements}
                        onChange={handleChange}
                        rows={4}
                        className={`${inputClasses} h-95 resize-none`}
                      />
                    </div>
                  </div>
                </div>

                {/* Contact Information Section - Only shown for guests */}
                {isGuest && (
                  <div className={sectionClasses}>
                    <h3 className="text-xl font-semibold text-gray-800 mb-6">Contact Information</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div>
                        <label className={labelClasses}>
                          Full Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          className={inputClasses}
                          required
                        />
                      </div>
                      <div>
                        <label className={labelClasses}>
                          Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          className={inputClasses}
                          required
                        />
                      </div>
                      <div className="md:col-span-2">
                        <label className={labelClasses}>
                          Phone Number
                        </label>
                        <input
                          type="tel"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          className={inputClasses}
                          required
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* Submit Button */}
              <div className="flex justify-center pt-4">
                <button
                  type="submit"
                  className="px-8 py-4 bg-blue-600 hover:bg-blue-700 rounded-lg text-white font-semibold text-lg transform transition-all duration-200 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Submit Booking Request
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VenueBooking;