import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const GoogleIcon = () => (
  <svg 
    className="h-5 w-5" 
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
    />
    <path
      fill="currentColor"
      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
    />
    <path
      fill="currentColor"
      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
    />
    <path
      fill="currentColor"
      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
    />
  </svg>
);

const AlertMessage = ({ message, type }) => (
  <div className={`p-4 mb-4 rounded-md ${
    type === 'success' 
      ? 'bg-green-100 border border-green-400 text-green-700' 
      : 'bg-red-100 border border-red-400 text-red-700'
  }`}>
    <p className="text-sm">{message}</p>
  </div>
);

const AuthPopup = ({ 
  isOpen, 
  onClose, 
  onGoogleSignIn, 
  onFacebookSignIn, 
  onGitHubSignIn, 
  onLoginSubmit, 
  onSignupSubmit
}) => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    password: ''
  });
  const [alert, setAlert] = useState({ show: false, message: '', type: 'success' });
  const navigate = useNavigate();

  if (!isOpen) return null;

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const showSuccessMessage = (message) => {
    setAlert({ show: true, message, type: 'success' });
    setTimeout(() => {
      setAlert({ show: false, message: '', type: 'success' });
      navigate('/venue-booking');
    }, 2000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (isSignUp) {
      try {
        const response = await fetch('http://localhost:2018/api/users/signup', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData)
        });

        const data = await response.json();
        
        if (response.ok) {
          showSuccessMessage('Signup successful! Redirecting to venue booking...');
        } else {
          setAlert({
            show: true,
            message: data.message || 'Signup failed. Please try again.',
            type: 'error'
          });
        }
      } catch (error) {
        console.error('Signup error:', error);
        setAlert({
          show: true,
          message: 'An error occurred. Please try again.',
          type: 'error'
        });
      }
    } else {
      const signInData = {
        username: formData.phone || formData.email,
        password: formData.password
      };

      try {
        const response = await fetch('http://localhost:2018/api/users/signin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(signInData)
        });

        const data = await response.json();
        
        if (response.ok) {
          showSuccessMessage('Sign in successful! Redirecting to venue booking...');
        } else {
          setAlert({
            show: true,
            message: data.message || 'Sign in failed. Please try again.',
            type: 'error'
          });
        }
      } catch (error) {
        console.error('SignIn error:', error);
        setAlert({
          show: true,
          message: 'An error occurred. Please try again.',
          type: 'error'
        });
      }
    }
  };

  const onGuestBooking = () => {
    navigate('/venue-booking');
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const toggleForm = () => {
    setIsSignUp(!isSignUp);
    setFormData({ email: '', phone: '', password: '' });
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={handleBackdropClick}
    >
      <div className="bg-white rounded-lg p-6 w-full max-w-md mx-4 relative">
        {/* Close button */}
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-xl"
        >
          ×
        </button>

        {/* Alert Message */}
        {alert.show && (
          <AlertMessage message={alert.message} type={alert.type} />
        )}

        {/* Rest of your existing JSX */}
        <div className="mb-6">
          <div className="flex justify-center space-x-4 mb-4">
            <button 
              className={`px-4 py-2 ${!isSignUp ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500'}`}
              onClick={() => setIsSignUp(false)}
            >
              Sign In
            </button>
            <button 
              className={`px-4 py-2 ${isSignUp ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500'}`}
              onClick={() => setIsSignUp(true)}
            >
              Sign Up
            </button>
          </div>
        </div>

        <div className="space-y-3 mb-6">
          <button 
            onClick={onGoogleSignIn}
            className="w-full flex items-center justify-center gap-2 px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50 transition-colors"
          >
            <GoogleIcon />
            <span>Continue with Google</span>
          </button>
        </div>

        <div className="relative my-6">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-xs uppercase">
            <span className="bg-white px-2 text-gray-500">Or</span>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4 mb-6">
          {isSignUp && (
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
          )}
          <div>
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
              Phone
            </label>
            <input
              type="number"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <button 
            type="submit"
            className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
          >
            {isSignUp ? 'Sign Up' : 'Sign In'}
          </button>
        </form>

        <button 
          onClick={onGuestBooking}
          className="w-full px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-md transition-colors"
        >
          Book without Sign in
        </button>
      </div>
    </div>
  );
};

export default AuthPopup;